import React from "react";
import propTypes from "prop-types";

function FormPaymentCheckbox ({ onChange, name, type, label, value }) {
  return (
    <label className="payment-option">
      <input
        onChange={onChange}
        type={type}
        name={name}
        value={value}
        className="payment-option-input"
      />
      <div className="payment-option-mark">
        <div className="payment-option-image">
          <img src="" alt="" />
        </div>
        <div className="payment-option-label">
          {label}
        </div>
      </div>
    </label>
  );
}

FormPaymentCheckbox.defaultProps = {
  type: "checkbox",
  value: "",
};

FormPaymentCheckbox.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string.isRequired,
  type: propTypes.oneOf(["radio", "checkbox"]).isRequired,
  label: propTypes.string.isRequired,
  value: propTypes
    .oneOfType([
      propTypes.string,
      propTypes.number,
      propTypes.bool])
    .isRequired,
  checked: propTypes.bool,
};

export default FormPaymentCheckbox;
