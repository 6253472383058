import React, { createContext, useContext, useState } from "react";
import { Button as ButtonAtom } from "components/atoms";

const CollapseContext = createContext();

function useCollapseContext () {
  const context = useContext(CollapseContext);

  if (!context) {
    throw new Error(
      "Child components cannot be rendered outside the parent component!"
    );
  }

  return context;
}

function Collapse ({ children, open }) {
  const [show, setShow] = useState(open);

  return (
    <CollapseContext.Provider value={{ show, setShow }}>
      <div>{children}</div>
    </CollapseContext.Provider>
  );
}

function Button ({ children, ...rest }) {
  const { setShow } = useCollapseContext();

  return (
    <ButtonAtom onClick={() => setShow((show) => !show)} {...rest}>
      {children}
    </ButtonAtom>
  );
}
Collapse.Button = Button;

function Content ({ children, className }) {
  const { show } = useCollapseContext();

  const contentClass = ["collapse"];

  if (show) contentClass.push("collapse-active");
  if (className) contentClass.push(className);

  return (
    <div className={contentClass.join(" ")}>
      {children}
    </div>
  );
}
Collapse.Content = Content;

export default Collapse;
