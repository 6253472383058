import React from "react";
import propTypes from "prop-types";

const SidebarButton = ({ icon, onClick }) => {
  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="sidebar-logout"
    >
      <i className={`feather ${icon}`} style={{ fontSize: "20px" }} />
    </button>
  );
};

SidebarButton.propTypes = {
  icon: propTypes.node.isRequired,
};

export default SidebarButton;
