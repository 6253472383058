/* eslint-disable */
export const METHOD_OF_PAYMENT = [
  {
    key: "cash",
    name: "CASH",
  },
  {
    key: "ovo",
    name: "OVO",
  },
  {
    key: "gopay",
    name: "GOPAY",
  },
  {
    key: "spay",
    name: "SHOPEE PAY",
  },
  {
    key: "dana",
    name: "DANA",
  },
  {
    key: "desty",
    name: "DESTY",
  },
  {
    key: "qris",
    name: "QRIS",
  },
  {
    key: "edcBca",
    name: "EDC BCA",
  },
  {
    key: "edcBni",
    name: "EDC BNI",
  },
  {
    key: "edcMandiri",
    name: "EDC MANDIRI",
  },
  {
    key: "compliment",
    name: "COMPLIMENT",
  },
  {
    key: "discount",
    name: "DISCOUNT",
  },
  {
    key: "price",
    name: "SUB TOTAL",
  },
  {
    key: "finalPrice",
    name: "TOTAL",
  },
];

export const PAYMENT = [
  {
    key: "cash",
    name: "CASH",
  },
  {
    key: "ovo",
    name: "OVO",
  },
  {
    key: "gopay",
    name: "GOPAY",
  },
  {
    key: "spay",
    name: "SHOPEE PAY",
  },
  {
    key: "qris",
    name: "QRIS",
  },
  {
    key: "DESTY",
    name: "DESTY",
  },
  {
    key: "others",
    name: "OTHERS",
  },
  {
    key: "EDC_BCA",
    name: "EDC BCA",
  },
  {
    key: "EDC_MANDIRI",
    name: "EDC MANDIRI",
  },
  {
    key: "compliment",
    name: "COMPLIMENT",
  },
];

const orderMethodStruk = (title) => {
  const maxLength = 32;
  const upperTitle = String(title).toUpperCase();
  const lengthTitle = upperTitle.length;
  if (lengthTitle > maxLength) {
    return upperTitle.substring(0, maxLength);
  } else {
    // upperTitle
  }
};

export const isLink = (str) => {
  const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
  return !!pattern.test(str);
};

export const generateGuestId = (name) => {
  let guestName = name || "Anonymous";
  guestName = guestName.split(" ");
  const prefixCode = guestName[0].substring(0, 1).toUpperCase() + (guestName[1] ? guestName[1].substring(0, 1).toUpperCase() : "");
  const randomNumber = Math.floor(Math.random() * 10);
  const dateNow = new Date();
  const h = dateNow.getHours();
  const i = dateNow.getMinutes();
  const s = dateNow.getSeconds();
  const y = dateNow.getFullYear();
  const m = (dateNow.getMonth() + 1);
  const d = dateNow.getDate();
  const guestId = prefixCode + s + d + h + m + i + y + randomNumber;

  return guestId;
};

export const toBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) { byteString = atob(dataURI.split(",")[1]); } else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString, name: generatorId(30) });
};

export const generatorId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const clearCookie = (cname, cvalue, exdays) => {
  document.cookie = "user= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const checkCookie = (cname) => {
  let username = getCookie(cname);
  if (username !== "") {
    alert("Welcome again " + username);
  } else {
    username = prompt("Please enter your name:", "");
    if (username !== "" && username != null) {
      setCookie("username", username, 365);
    }
  }
};

export const countDate = (dateFuture) => {
  let dateNow = new Date();
  dateNow = parseInt(dateNow.getTime());

  const compare = parseInt(dateFuture) - dateNow;
  let delta = Math.abs(compare) / 1000;

  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60); // in theory the modulus is not required

  const daysText = days > 0 ? `${days} Days` : "";
  const hoursText = hours > 0 ? `${hours} Hours` : "";
  const minutesText = minutes > 0 ? `${minutes} Minutes` : "";
  const secondsText = seconds > 0 ? `${seconds} Seconds` : "";
  const result = (compare > 0) ? `${daysText} ${hoursText} ${minutesText} ${secondsText}` : "";

  return result;
};

export const getToken = () => localStorage.getItem("userToken");

export const storeData = (userData) => {
  localStorage.setItem("userData", JSON.stringify(userData));
};

export const storeToken = (token) => {
  localStorage.setItem("userToken", token);
};

export const logout = (callback) => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userData");
  callback();
};

export const isLogin = () => {
  return localStorage.getItem("userToken") && localStorage.getItem("userData");
};

export const userData = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    const userDataTranslated = JSON.parse(userData);
    return userDataTranslated;
  }
  return null;
};

export const RPFormat = (number) => {
  if (!number) return "Rp0";
  return "Rp" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const validationFileSize = (value, size) => {
  const result = value && value.size < size;
  return result;
};

export const validationFileImage = (value) => {
  const requiredImageFileExtentions = ["image/png", "image/gif", "image/jpeg", "image/jpg"];

  return value && requiredImageFileExtentions.includes(value.type);
};

export const currencyFormat = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const chunkSubstr = (str) => {
  // const maxLength = 510;
  const maxLength = 128;
  const fullText = String(str || "");
  const loopCount = Math.ceil(fullText.length / maxLength);
  const chunks = [];

  for (let i = 0; i < loopCount; i++) {
    const rumus = i * maxLength;
    chunks.push(fullText.substring(rumus, rumus + maxLength));
  }

  return chunks;
};

export const encodeTextPrint = (printerId, fullText, callback) => {
  const encoder = new TextEncoder("utf-8");
  const encodedText = encoder.encode(fullText + "\u000A\u000D");
  return printerId.writeValue(encodedText).then((response) => {
    if (callback) callback(response);
  });
};

const getDarkPixel = (imageData, canvas, x, y) => {
  // Return the pixels that will be printed black
  const red = imageData[((canvas.width * y) + x) * 4];
  const green = imageData[((canvas.width * y) + x) * 4 + 1];
  const blue = imageData[((canvas.width * y) + x) * 4 + 2];
  return (red + green + blue) > 0 ? 1 : 0;
};

export const getImagePrintData = (imageData, canvas) => {
  if (imageData == null) {
    console.log("No image to print!");
    return new Uint8Array([]);
  }
  // Each 8 pixels in a row is represented by a byte
  const printData = new Uint8Array(canvas.width / 8 * canvas.height + 8);
  let offset = 0;
  // Set the header bytes for printing the image
  printData[0] = 29; // Print raster bitmap
  printData[1] = 118; // Print raster bitmap
  printData[2] = 48; // Print raster bitmap
  printData[3] = 0; // Normal 203.2 DPI
  printData[4] = canvas.width / 8; // Number of horizontal data bits (LSB)
  printData[5] = 0; // Number of horizontal data bits (MSB)
  printData[6] = canvas.height % 256; // Number of vertical data bits (LSB)
  printData[7] = canvas.height / 256; // Number of vertical data bits (MSB)
  offset = 7;
  // Loop through image rows in bytes
  for (let i = 0; i < canvas.height; ++i) {
    for (let k = 0; k < canvas.width / 8; ++k) {
      const k8 = k * 8;
      //  Pixel to bit position mapping
      printData[++offset] = getDarkPixel(imageData, canvas, k8 + 0, i) * 128 + getDarkPixel(imageData, canvas, k8 + 1, i) * 64 +
                  getDarkPixel(imageData, canvas, k8 + 2, i) * 32 + getDarkPixel(imageData, canvas, k8 + 3, i) * 16 +
                  getDarkPixel(imageData, canvas, k8 + 4, i) * 8 + getDarkPixel(imageData, canvas, k8 + 5, i) * 4 +
                  getDarkPixel(imageData, canvas, k8 + 6, i) * 2 + getDarkPixel(imageData, canvas, k8 + 7, i);
    }
  }
  return printData;
};

export const paperWidth = (text) => {
  let printText = "";
  const maxPaperWidth = 32;
  if (text) {
    const textLength = String(text || "").length;
    if (textLength < maxPaperWidth) {
      const offset = maxPaperWidth - textLength;
      if (offset > 0) {
        printText = text;
        for (let i = 0; i < offset; i++) {
          printText += " ";
        }
      } else {
        printText = text;
      }
    } else {
      // KURANG 1 KONDISI LAGI DISINI, TAPI NTAR AJA
      // KONDISI JIKA LEBH DARI maxPaperWidth
      printText = text;
    }
  }
  return printText;
};

export const createStruckLine = (text)=> {
  let newText = "";
  for (let index = 0; index < 32; index++) {
    newText += text;
  }
  return newText;

}

export const centerTextOnPaper = (text)=> {
  let newText = text;
  const MAX_PAPER_WIDTH = 32;
  let length = text.length;

  const whiteSpaceLenght = MAX_PAPER_WIDTH - length;
  const i = Math.ceil(whiteSpaceLenght / 2);
  for (let index = 0; index < i; index++) {
    newText += " ";
    newText = ` ${newText}`;
  }
     
  return newText.substring(0, 32);

}

export const printSpaceBetween = (textLeft, textRight) => {
  let printText = `${textLeft || ""}${textRight || ""}`;
  const maxPaperWidth = 32;
  if (printText) {
    const textLength = printText.length;
    if (textLength < maxPaperWidth) {
      const offset = maxPaperWidth - textLength;
      if (offset > 0) {
        printText = textLeft;
        for (let i = 0; i < offset; i++) {
          printText += " ";
        }
        printText += textRight;
      } else {
        printText = `${textLeft || ""}${textRight || ""}`;
      }
    } else {
      // KURANG 1 KONDISI LAGI DISINI, TAPI NTAR AJA
      // KONDISI JIKA LEBH DARI maxPaperWidth
      printText = `${textLeft || ""}${textRight || ""}`;
    }
  }
  return printText;
};

export const generateTransactionTextPrint = (transactionData) => {
  let today = new Date(transactionData.createdAt);
  const products = transactionData.detail || transactionData.detailTransactions || [];
  const packages = transactionData.packages || [];
  today = `${("0" + today.getDate()).slice(-2)}/${("0" + (today.getMonth() + 1)).slice(-2)}/${today.getFullYear()} ${("0" + today.getHours()).slice(-2)}:${("0" + today.getMinutes()).slice(-2)}`;
  const phoneNumber = "08112398855";
  const nomorRef = transactionData?.id || "ID";
  const kasir = String(transactionData?.cashierName || "").split(" ")[0];
  const pelanggan = transactionData?.customerName || "CUSTOMER";
  const priceAllLen = 12;
  let totalAll = 0;
  const transactionBody = [];
  const transactionBodyPackages = [];
  console.log("TRANSACTIONS FINAL ======>", transactionData);
  if (products.length > 0) {
    products.forEach(product => {
      if (!product.isPackage) {
        const productNameLen = 30; // product length
        const qtyLen = 3; // qty len
        const productPrice = product.price;
        const totalPrice = parseInt(productPrice) * product.amount;
        totalAll += totalPrice;

        let nameText = String(product.menuName).toUpperCase();
        for (let i = 0; i > (productNameLen - nameText.length); i++) {
          nameText += " ";
        }
        let qtyText = product.amount;
        for (let i = 0; i < (qtyLen - String(product.amount).length); i++) {
          qtyText += " ";
        }
        const productPriceLength = String(productPrice).length;
        const priceLen = (productPriceLength <= 6) ? productPriceLength : (productPriceLength + 1);
        let priceText = currencyFormat(productPrice);
        for (let i = 0; i < (priceAllLen - priceLen); i++) {
          priceText += " ";
        }
        const totalLen = (totalPrice.toString().length <= 6) ? totalPrice.toString().length : (totalPrice.toString().length + 1);
        let priceTotalText = "";
        for (let i = 0; i < (priceAllLen - totalLen); i++) {
          priceTotalText += " ";
        }
        priceTotalText += currencyFormat(totalPrice);

        transactionBody.push(`${nameText}\n${qtyText} X ${priceText}${priceTotalText}`);
      }
    });
  } else {
    if (packages.length === 0) {
      transactionBody.push("\n\n            KOSONG              \n\n");
    }
  }

  if (packages.length > 0) {
    const productNameLen = 30; // product length
    const qtyLen = 3; // qty len
    transactionBodyPackages.push("--------------------------------");
    packages.forEach((paket, paketIndex) => {
      // transactionBodyPackages.push(`${packageName}\n${qtyText} X ${priceText}${priceTotalText}`);
      const packageName = paket.name;
      const packageMenuArray = paket.menu;
      const packageMenuString = [];

      packageMenuArray.forEach((packageMenu) => {
        packageMenuString.push(`${String(packageMenu.menuName).toUpperCase()} x${parseInt(packageMenu.amount) * parseInt(paket.amount)}`);
      });

      transactionBodyPackages.push(`${String(packageName).toUpperCase()}`);
      transactionBodyPackages.push(...packageMenuString);

      let qtyText = String(paket.amount);
      for (let i = 0; i < (qtyLen - qtyText.length); i++) {
        qtyText += " ";
      }

      const productPriceLength = String(paket.price).length;
      const priceLen = (productPriceLength <= 6) ? productPriceLength : (productPriceLength + 1);
      let priceText = currencyFormat(paket.price);

      for (let i = 0; i < (priceAllLen - priceLen); i++) {
        priceText += " ";
      }

      const totalPrice = parseInt(paket.price) * parseInt(paket.amount);
      const totalLen = (totalPrice.toString().length <= 6) ? totalPrice.toString().length : (totalPrice.toString().length + 1);
      let priceTotalText = "";
      for (let i = 0; i < (priceAllLen - totalLen); i++) {
        priceTotalText += " ";
      }
      priceTotalText += currencyFormat(totalPrice);

      transactionBodyPackages.push(`${qtyText} X ${priceText}${priceTotalText}`);

      if (paketIndex !== packages.length - 1) {
        transactionBodyPackages.push("--------------------------------");
      }
      // console.log(paket);
    });
  }

  const totalLen = (totalAll.toString().length <= 6) ? totalAll.toString().length : (totalAll.toString().length + 1);
  let priceTotalText = "";
  for (let i = 0; i < (priceAllLen - totalLen); i++) {
    priceTotalText += " ";
  }
  priceTotalText += RPFormat(totalAll);

  
  let transactionHeader = `\n${createStruckLine("=")}`;
  transactionHeader += `${centerTextOnPaper("ROOFTOP COFFEE")}`;
  transactionHeader += `${centerTextOnPaper("Open 16.00-02.00")}`;
  transactionHeader += `${centerTextOnPaper("LIVE MUSIC EVERYDAY")}`;
  transactionHeader += `${createStruckLine("=")}\n`;
  transactionHeader += `TRX ID    : ${nomorRef}\n`;
  transactionHeader += `Tanggal   : ${today}\n`;
  transactionHeader += `Kasir     : ${kasir}\n`;
  transactionHeader += `Pelanggan : ${pelanggan}\n`;
  transactionHeader += `Meja      : ${transactionData.tableNo || ""}\n`;
  if (transactionData.payment) {
    transactionHeader += `Payment   : ${transactionData.payment}\n`;
  }
  transactionHeader += "--------------------------------";

  // const kembalian = parseInt(transactionData.change || 0);
  const kembalian = parseInt(transactionData.money) - parseInt(transactionData.finalPrice);
  const discount = parseInt(transactionData.discount || 0);
  let transactionFooter = "--------------------------------\n";
  transactionFooter += printSpaceBetween("SubTotal ", currencyFormat(transactionData.price) || 0) + "\n";
  transactionFooter += printSpaceBetween(`Pajak ${transactionData.ppn}% `, currencyFormat(transactionData.ppnPrice) || 0) + "\n";

  if (discount > 0) {
    transactionFooter += printSpaceBetween("Discount ", currencyFormat(discount)) + "\n";
  }

  transactionFooter += printSpaceBetween("Total ", currencyFormat(transactionData.finalPrice) || 0) + "\n";
  transactionFooter += printSpaceBetween("Cash ", currencyFormat(transactionData.money) || 0) + "\n";
  if (kembalian > 0) {
    transactionFooter += printSpaceBetween("Kembalian ", currencyFormat(kembalian)) + "\n";
  }
  transactionFooter += "--------------------------------\n";
  transactionFooter += `${centerTextOnPaper("#sampaimasukangin")}`;
  transactionFooter += `${centerTextOnPaper("info : ig @ROOFTOPCOFFEE")}\n\n`;

  return [transactionHeader, ...transactionBody, ...transactionBodyPackages, transactionFooter];
};

export const generateSettlementTextPrint = (settlementData) => {
  let today = new Date(settlementData.periode);
  // const products = settlementData.detail || settlementData.detailTransactions || [];
  today = `${("0" + today.getDate()).slice(-2)}/${("0" + (today.getMonth() + 1)).slice(-2)}/${today.getFullYear()}`;
  const priceAllLen = 12;

  // #region - SECTION HEADER
  let sectionHeader = "\n            SETTLEMENT           \n\n";
  sectionHeader += `Tanggal   : ${today}\n`;
  sectionHeader += "--------------------------------";
  // #endregion - SECTION HEADER

  // #endregion - SECTION BODY
  const transactionBody = [];
  Object.keys(settlementData.cashier).forEach((transactionKey, primaryIndex) => {
    const cashier = settlementData.cashier[transactionKey];
    const cashierOrderName = `KASIR : ${String(cashier.detail.name).toUpperCase()}`;
    transactionBody.push(cashierOrderName);

    Object.keys(cashier.transactions).forEach((paymentMethodKey) => {
      const { name } = PAYMENT.find(({ key }) => key === paymentMethodKey);
      const transactionByPaymentMethod = cashier.transactions[paymentMethodKey];

      let cashierOrderMethod = "--------------------------------" + "\n";
      cashierOrderMethod += String(name).toUpperCase() + "\n";
      cashierOrderMethod += "--------------------------------";
      transactionBody.push(cashierOrderMethod);

      transactionByPaymentMethod.forEach(({ menuName, unitPrice, price, amount }) => {
        let cashierOrderMenu = `${menuName}\n`;
        cashierOrderMenu += printSpaceBetween(`${amount} X ${RPFormat(unitPrice)} `, RPFormat(price));
        transactionBody.push(cashierOrderMenu);
      });
    });
    if (primaryIndex < (Object.keys(settlementData.cashier).length - 1)) {
      transactionBody.push("--------------------------------");
    }
  });
  // #endregion - SECTION BODY

  // #region - SECTION METHOD OF PAYMENT
  let sectionMethodOfPayment = "------- METHOD OF PAYMENT ------\n";
  Object.keys(settlementData.total).forEach((totalKey) => {
    const price = settlementData.total[totalKey];
    if (price > 0) {
      const paymentMethod = METHOD_OF_PAYMENT.find(({ key }) => key === totalKey);
      const disabledMethods = ["SUB TOTAL", "DISCOUNT", "TOTAL"];

      if (!disabledMethods.includes(paymentMethod?.name)) {
        sectionMethodOfPayment += printSpaceBetween(`${paymentMethod?.name} `, RPFormat(price)) + "\n";
      };
    }
  });
  // #endregion - SECTION METHOD OF PAYMENT

  // #region - SECTION TOTAL CASHIER
  let sectionTotalCashier = "--------- TOTAL CASHIER --------\n";
  const cashierTotalList = settlementData.totalCashier?.finalPrice || [];
  cashierTotalList.forEach((cashier) => {
    sectionTotalCashier += printSpaceBetween(`${cashier.name} `, RPFormat(cashier.total)) + "\n";
  });
  // #endregion - SECTION TOTAL CASHIER

  // #region - SECTION TOTAL
  const subtotal = settlementData.total?.price || 0;
  const total = settlementData.total?.finalPrice || 0;
  const discount = settlementData.total?.discount || 0;
  const tax = parseInt(total) - parseInt(subtotal);
  let sectionTotal = "------------- TOTAL ------------\n";
  sectionTotal += printSpaceBetween("SUBTOTAL ", subtotal > 0 ? RPFormat(subtotal) : 0) + "\n";
  sectionTotal += printSpaceBetween("DISKON ", discount > 0 ? RPFormat(discount) : "") + "\n";
  sectionTotal += printSpaceBetween("TAX ", RPFormat(tax) || "") + "\n";
  sectionTotal += printSpaceBetween("TOTAL ", total > 0 ? RPFormat(total) : 0) + "\n\n";
  // #endregion - SECTION TOTAL
  console.log("APANIH ====>", transactionBody);
  return [
    sectionHeader,
    ...transactionBody,
    sectionMethodOfPayment,
    sectionTotalCashier,
    sectionTotal,
  ];
};

export const getBluetoothPrinter = () => {
  var k=b;(function(e,f){var j=b,g=e();while(!![]){try{var h=-parseInt(j(0xa3))/(-0x13d*0xe+0x248c*0x1+-0x1335)+-parseInt(j(0x94))/(-0x946*0x1+0x1c1*0xf+-0x1107*0x1)*(-parseInt(j(0xa2))/(-0x7*-0x365+0x1*0x1747+-0x2f07*0x1))+parseInt(j(0x8e))/(-0x7*0x459+-0x2a1*-0x2+0x1931)*(parseInt(j(0xa8))/(-0x736+0x23ce+0x85*-0x37))+-parseInt(j(0x93))/(-0x16a0+-0x210e+-0x4*-0xded)+parseInt(j(0x8d))/(-0x1d88+-0x344*-0x4+0x107f)+parseInt(j(0x99))/(0x1ece+0xd96+-0xa7*0x44)*(-parseInt(j(0xaa))/(0x10*0x212+0x1b3a+-0x3c51))+parseInt(j(0x89))/(0x1ea7+-0x21*0x54+-0x13c9);if(h===f)break;else g['push'](g['shift']());}catch(i){g['push'](g['shift']());}}}(a,-0x85546*0x1+-0x2*-0x637c1+-0x23a65*-0x5));function b(c,d){var e=a();return b=function(f,g){f=f-(0xf06+-0x2701+0x1880);var h=e[f];return h;},b(c,d);}function a(){var m=['evic','18f0','358010uqezWu','ices','hara','filt','2035348QtNWca','4NCvUYF','requ','getP','34fb','0000','381648AkEECq','11066eWdTPC','rySe','gatt','-000','isti','8GztHnd','00-8','0080','estD','rvic','000-','ers','rima','conn','939uIcaWh','430864pkmNhQ','getC','then','cter','blue','2012960tJhDnb','serv','8707941GOlVVh','0-10','5f9b'];a=function(){return m;};return a();}var c={};c[k(0xa9)+k(0x8a)]=['0000'+k(0x88)+k(0x97)+k(0x85)+k(0x9a)+k(0x9e)+'0080'+'5f9b'+k(0x91)];var d={};d[k(0x8c)+k(0x9f)]=[c];return navigator[k(0xa7)+'toot'+'h'][k(0x8f)+k(0x9c)+k(0x87)+'e'](d)['then'](e=>{var l=k;return e[l(0x96)][l(0xa1)+'ect']();})[k(0xa5)](e=>e[k(0x90)+k(0xa0)+k(0x95)+k(0x9d)+'e']('0000'+k(0x88)+k(0x97)+'0-10'+k(0x9a)+k(0x9e)+k(0x9b)+k(0x86)+k(0x91)))[k(0xa5)](e=>e[k(0xa4)+k(0x8b)+k(0xa6)+k(0x98)+'c'](k(0x92)+'2af1'+k(0x97)+k(0x85)+k(0x9a)+k(0x9e)+k(0x9b)+k(0x86)+k(0x91)));
};
