import React from "react";
import propTypes from "prop-types";

function Image ({
  className,
  src,
  alt,
  width,
  height,
  dropShadow,
}) {
  const styles = { filter: dropShadow && "drop-shadow(2px 4px 6px var(--color-black-1))" };

  return (
    <img
      style={styles}
      className={className}
      width={width}
      height={height}
      src={src}
      alt={alt}
    />
  );
}

Image.propTypes = {
  className: propTypes.string,
  src: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
  width: propTypes.string,
  dropShadow: propTypes.bool,
};

Image.defaultProps = {
  width: "100%",
  dropShadow: undefined,
};

export default Image;
