import React from "react";
import propTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

const AuthenticatedRoute = ({ redirectPath }) => {
  const token = localStorage.getItem("userToken");

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

AuthenticatedRoute.propTypes = {
  redirectPath: propTypes.string,
};

AuthenticatedRoute.defaultProps = {
  redirectPath: "/login",
};

export default AuthenticatedRoute;
