import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { Box, Flex, Image } from "components/atoms";

const FormImage = ({
  onChange,
  name,
  type,
  placeholder,
  label,
  errormessage,
  previewImage,
}) => {
  // #region - Initial states
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  // #endregion - Initial states

  // #region - Side Effect
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      return setPreview(undefined);
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  // #endregion - Side Effect

  // #region - Handlers
  const handleInputChange = (event) => {
    onChange(event);

    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(event.target.files[0]);
  };
  // #endregion - Handlers

  return (
    <div className="position-relative">
      {label && (
        <div className="text-weight-medium mb-2">
          <span className="text-left">{label}</span>
          {errormessage && (
            <span className="color-primary">{` - ${errormessage}`}</span>
          )}
        </div>
      )}
      <input
        type="file"
        name={name}
        className="form-input"
        placeholder={placeholder}
        onChange={handleInputChange}
        accept="image/png, image/gif, image/jpeg, image/jpg"
      />
      {errormessage && (
        <div className="form-input-error-icon">
          <i className="feather icon-alert-triangle" />
        </div>
      )}

      {/* Preview Image */}
      {type === "image" && (
        <>
          {previewImage && (
            <div className="my-1">
              <small className="color-primary">*Kosongkan jika tidak akan diganti</small>
            </div>
          )}
          <Box className="my-2" width="150px" height="150px" shadow>
            <Flex className="height-full" justify="center" align="center">
              {
                selectedFile || previewImage
                  ? (
                    <Image
                      src={preview || previewImage}
                      width="100%"
                      alt="Gambar"
                    />
                  )
                  : (
                    <i className="feather icon-help-circle" style={{ fontSize: "72px" }} />
                  )
              }
            </Flex>
          </Box>
        </>
      )}
    </div>
  );
};

FormImage.defaultProps = {
  type: "text",
  previewImage: "",
};

FormImage.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string,
  type: propTypes.string,
  placeholder: propTypes.string,
  label: propTypes.string,
  errormessage: propTypes.string,
  previewImage: propTypes.string,
};

export default FormImage;
