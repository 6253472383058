import React, { useReducer, useEffect } from "react";
import propTypes from "prop-types";
import { useTable, usePagination, useFilters } from "react-table";
import { Button, FilterSearch, Flex } from "components/atoms";
import { FormInput, FormSelect } from "components/molecules";
import { PARAMS_QUERY } from "constants/shared";

function Table ({
  loading,
  columns,
  data,
  pageCount: controlledPageCount,
  totalData,
  handleChangeTable,
  currentPage,
}) {
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    PARAMS_QUERY
  );

  useEffect(() => {
    if (params !== PARAMS_QUERY) {
      handleChangeTable(params);
    }
  }, [params]);

  const defaultColumn = React.useMemo(
    () => ({
      FilterSearch,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    usePagination
  );

  // #region - Handlers
  const handlePrevPagination = () => {
    let prevPage = 1;
    if (currentPage > 1) {
      prevPage = currentPage - 1;
    }
    setParams({ page: prevPage });
  };

  const handleNextPagination = () => {
    let nextPage = 1;
    if (currentPage !== controlledPageCount) {
      nextPage = currentPage + 1;
    } else {
      nextPage = currentPage;
    }
    setParams({ page: nextPage });
  };

  const handleGoToPage = (event) => {
    const { value } = event.target;
    const page = value ? Number(value) : 1;

    if (page >= 1 && page <= controlledPageCount) {
      setParams({ page });
    }
  };

  const handlePageSize = (event) => {
    const { value } = event.target;
    const limit = Number(value);
    setPageSize(limit);
    setParams({ limit });
  };

  const handleSearch = (value, name) => {
    const search = { search: { [name]: value } };
    setParams(search.search);
  };
  // #endregion - Handlers

  return (
    <>
      <div className="overflow-x-scroll">
        <table className="table" {...getTableProps()}>
          <thead className="table-head">
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th key={columnIndex} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th key={columnIndex} {...column.getHeaderProps()}>
                    <div>
                      {column.canFilter && column.filter?.type === "search" &&
                        (
                          <FormInput
                            onChange={(event) => handleSearch(event.target.value, column.filter?.name)}
                            placeholder="Search ...."
                            size="sm"
                          />
                        )
                      }
                      {column.canFilter && column.filter?.type === "select" &&
                        (
                          <FormSelect
                            onChange={(event) => handleSearch(event.target.value, column.filter?.name)}
                            size="sm"
                          >
                            {column.filter?.dataSelect.map(({ key, name }, index) => (
                              <option key={index} value={key}>{name}</option>
                            ))}
                          </FormSelect>
                        )
                      }
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            {
              loading
                ? (
                  <tr>
                    <td className="text-left" colSpan="100%">Loading ...</td>
                  </tr>
                )
                : page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr key={rowIndex} {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        return <td key={cellIndex} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })
            }
          </tbody>
        </table>
      </div>

      {/* Footer */}
      <Flex className="mt-5" align="center" justify="space-between">
        <div className="mr-2">Total Data: <strong className="color-black-1">{totalData}</strong></div>
        <Flex justify="end">
          <Flex align="center">
            <Flex className="mr-3" align="center">
              <div className="mr-2">Go to page:</div>
              <input
                onChange={handleGoToPage}
                className="pagination-gotopage"
                type="number"
              />
            </Flex>{" "}
            <Flex className="mr-3" align="center">
              <span className="mr-2">Page</span>
              <strong className="text-center">
                {currentPage || pageIndex + 1} of {controlledPageCount}
              </strong>{" "}
            </Flex>
            <>
              <Button
                onClick={handlePrevPagination}
                className="mr-1"
                variant="primary"
                type="pagination"
                outline
                size="lg"
              >
                {"<"}
              </Button>{" "}
              <Button
                onClick={handleNextPagination}
                className="mr-3"
                variant="primary"
                type="pagination"
                outline
              >
                {">"}
              </Button>
            </>
            <FormSelect
              onChange={handlePageSize}
              value={pageSize}
              size="sm"
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </FormSelect>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

Table.propTypes = {
  loading: propTypes.bool,
  columns: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
  totalData: propTypes.number,
  handleChangeTable: propTypes.func.isRequired,
};

Table.defaultProps = {
  loading: false,
  currentPage: 1,
  totalData: 0,
};

export default Table;
