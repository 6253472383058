import React from "react";
import propTypes from "prop-types";
const FormInput = ({ onChange, label, errormessage, size, className, ...props }) => {
  const formInputClass = ["form-input"];
  if (size) formInputClass.push(`form-input-${size}`);
  if (className) formInputClass.push(className);

  return (
    <>
      {label && (
        <div className="text-weight-medium mb-2">
          <span className="text-left">{label}</span>
          {errormessage && (
            <small className="color-primary">{` - ${errormessage}`}</small>
          )}
        </div>
      )}
      <div className="position-relative">
        <input
          {...props}
          {...(onChange ? { onChange: onChange } : {})}
          className={formInputClass.join(" ")}
        />
        {errormessage && (
          <div className="form-input-error-icon">
            <i className="feather icon-alert-triangle" />
          </div>
        )}
      </div>
    </>
  );
};

FormInput.propTypes = {
  onChange: propTypes.func,
  label: propTypes.string,
  errormessage: propTypes.string,
  size: propTypes.oneOf(["sm", "lg"]),
  className: propTypes.string,
};

export default FormInput;
