import React from "react";
import propTypes from "prop-types";

function Row ({
  children,
  className,
}) {
  const rowClassName = ["row"];

  if (className) rowClassName.push(className);

  return (
    <div className={rowClassName.join(" ")}>{children}</div>
  );
}

Row.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
};

export default Row;
