import React from "react";
import propTypes from "prop-types";

const Box = (props) => {
  const {
    onClick,
    children,
    style,
    className,
    shadow,
    fullWidth,
    radius,
    minWidth,
    maxWidth,
    maxContent,
    width,
    height,
  } = props;
  const boxClassName = ["box"];

  if (shadow) boxClassName.push("box-shadow");
  if (fullWidth) boxClassName.push("box-full-width");
  if (maxContent) boxClassName.push("box-max-content");
  if (radius) boxClassName.push(`box-radius-${radius}`);
  if (className) boxClassName.push(className);

  const boxStyles = { width, minWidth, maxWidth, height, ...style };

  return (
    <div onClick={onClick} className={boxClassName.join(" ")} style={boxStyles}>
      {children}
    </div>
  );
};

Box.propTypes = {
  onClick: propTypes.func,
  children: propTypes.node.isRequired,
  className: propTypes.string,
  style: propTypes.object,
  shadow: propTypes.bool,
  fullWidth: propTypes.bool,
  radius: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  minWidth: propTypes.string,
  maxWidth: propTypes.string,
  width: propTypes.string,
  height: propTypes.string,
};

export default Box;
