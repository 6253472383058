import React from "react";
import { Flex } from "..";

const LoadingPage = () => {
  return (
    <Flex justify="center" align="center" className="height-full">
      <i className="feather icon-loader" style={{ fontSize: "24px" }} />
    </Flex>
  );
};

export default LoadingPage;
