import React, { useState } from "react";
import PropTypes from "prop-types";

const TableAction = ({ buttons }) => {
  const [show, setShow] = useState(false);
  const onAction = (onClick) => {
    setShow(false);
    onClick();
  };

  return (
    <div className="position-relative">
      <div className="table-action">
        <button onClick={() => setShow((currentShow) => !currentShow)} className="table-action-button">
          <i className={`feather icon-${show ? "x" : "list"}`} />
        </button>
      </div>
      <button className={`table-dropdown ${show ? "show" : "hide"}`}>
        {buttons.map(({ text, onClick }, buttonIndex) => (
          <div onClick={() => onAction(onClick)} key={buttonIndex} className="table-dropdown-list">{text}</div>
        ))}
      </button>
    </div>
  );
};

TableAction.propTypes = {
  buttons: PropTypes.array,
};

TableAction.defaultProps = {
  buttons: [],
};

export default TableAction;
