import React from "react";
import propTypes from "prop-types";

function Grid ({ children, className, gap, templateColumns }) {
  const styles = {};
  const gridClassName = ["grid"];

  if (gap) styles.gap = gap;
  if (templateColumns) styles.gridTemplateColumns = templateColumns;
  if (className) gridClassName.push(className);

  return (
    <div style={styles} className={gridClassName.join(" ")}>{children}</div>
  );
}

Grid.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  gap: propTypes.string,
  templateColumns: propTypes.string,
};

export default Grid;
