import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Button } from "components/atoms";
import { Modal } from "components/organisms";
import { getBluetoothPrinter } from "utils/helpers";
import Routes from "./routes.js";
import { setBluetoothPrinter } from "store/actions/bluetoothPrinterAction";
import { useDispatch, useSelector } from "react-redux";

// #region - IMPORT STYLE
import "./assets/feather-icon/feather-icon.css";
import "./assets/scss/main.scss";
import { Navigate, useNavigate } from "react-router-dom";
// #endregion - IMPORT STYLE

function App () {
  let printer;
  const dispatch = useDispatch();
  const [showModalExpired, setShowModalExpired] = useState(false);
  const bluetoothCharacteristic = useSelector((state) => state.bluetoothPrinterReducer.bluetoothCharacteristic);
  const [connected, setConnected] = useState(false);
  const [isWarningShow, setIsWarningShow] = useState(true);
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const tokenDecoded = jwtDecode(token);
      const tokenExpire = parseInt(tokenDecoded.exp) * 1000;
      const betweenTwoDate = tokenExpire - Date.now();

      if (betweenTwoDate <= 0) {
        setShowModalExpired(true);
      }

      if (betweenTwoDate > 1) {
        setTimeout(() => {
          setShowModalExpired(true);
        }, betweenTwoDate);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  };

  return (
    <>
      <Modal show={showModalExpired}>
        <div className="px-4 pt-2 pb-3">
          <h1>SESI KAMU SUDAH HABIS</h1>
          <div className="text-center mt-2">
            <Button
              type="button"
              className="px-4"
              color="red"
              radius="lg"
              onClick={logout}
              md
            >
              LOGIN
            </Button>
          </div>
        </div>
      </Modal>
      <Routes />
    </>
  );
}

export default App;
