/* eslint-disable react/react-in-jsx-scope */
import { Image } from "components/atoms";
import { SidebarButton, SidebarLink } from "components/molecules";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { logout } from "utils/helpers";

const Sidebar = ({ onClose, show }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(() => {
      navigate("/login");
    });
  };

  return (
    <div className={`sidebar ${show ? "open" : ""}`}>
      <div className="sidebar-toggle">
        <button className="btn-none" onClick={onClose}>
          <i className="feather icon-arrow-left sidebar-icon" />
        </button>
      </div>
      <div className="sidebar-wrapper">
        <div className="sidebar-logo">
          <Image
            src={
              process.env.REACT_APP_CASHIER_PRODUCTION_API +
              "assets/images/rooftop-64.png"
            }
            className="width-full"
            alt="Logo"
          />
        </div>
        <SidebarLink icon="icon-home" href="/" />
        <SidebarLink icon="icon-dollar-sign" href="/transactions" />
        <SidebarLink icon="icon-map" href="/settlement" />
        <SidebarLink icon="icon-settings" href="/setting" />
        <SidebarLink icon="icon-user" href="/profile" />
      </div>
      <SidebarButton icon="icon-log-out" onClick={handleLogout} />
    </div>
  );
};

Sidebar.propTypes = {
  onClose: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
};

export default Sidebar;
