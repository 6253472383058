import React from "react";
import propTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

const UnAuthenticatedRoute = ({ redirectPath }) => {
  const userToken = localStorage.getItem("userToken");

  if (userToken) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

UnAuthenticatedRoute.propTypes = {
  redirectPath: propTypes.string,
};

UnAuthenticatedRoute.defaultProps = {
  redirectPath: "/",
};

export default UnAuthenticatedRoute;
