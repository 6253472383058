import React from "react";
import propTypes from "prop-types";

const Flex = ({
  children,
  className,
  wrap,
  inline,
  justify,
  align,
  direction,
}) => {
  const flexClassName = ["display-flex"];

  if (wrap) flexClassName.push("flex-wrap");
  if (inline) flexClassName.push("display-inline-flex");
  if (justify) flexClassName.push(`align-y-${justify}`);
  if (align) flexClassName.push(`align-x-${align}`);
  if (className) flexClassName.push(className);
  if (direction) flexClassName.push(`flex-direction-${direction}`);

  return (
    <div className={flexClassName.join(" ")}>
      {children}
    </div>
  );
};

Flex.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  wrap: propTypes.bool,
  inline: propTypes.bool,
  justify: propTypes.oneOf(["space-around", "space-between", "center", "start", "end"]),
  align: propTypes.oneOf(["space-around", "space-between", "center", "start", "end"]),
  direction: propTypes.oneOf(["column", "row"]),
};

export default Flex;
