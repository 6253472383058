export const PARAMS_QUERY = {
  limit: 10,
  page: 1,
};

export const PAGINATION = {
  totalPage: 0,
  totalData: 0,
};

export const DATA_TABLE = {
  data: [],
  params: PARAMS_QUERY,
  pageCount: 1,
  totalData: 0,
  totalPage: 0,
  loading: true,
};

export const PAYMENT = [
  {
    key: "cash",
    name: "CASH",
  },
  {
    key: "qris",
    name: "QRIS",
  },
  {
    key: "EDC_BCA",
    name: "EDC BCA",
  },
  {
    key: "EDC_MANDIRI",
    name: "EDC MANDIRI",
  },
  {
    key: "others",
    name: "LAINNYA...",
  },
  {
    key: "compliment",
    name: "COMPLIMENT",
  },
];

export const METHOD_OF_PAYMENT = [
  {
    key: "cash",
    name: "CASH",
  },
  {
    key: "ovo",
    name: "OVO",
  },
  {
    key: "gopay",
    name: "GOPAY",
  },
  {
    key: "spay",
    name: "SHOPEE PAY",
  },
  {
    key: "dana",
    name: "DANA",
  },
  {
    key: "desty",
    name: "DESTY",
  },
  {
    key: "qris",
    name: "QRIS",
  },
  {
    key: "edcBca",
    name: "EDC BCA",
  },
  {
    key: "edcBni",
    name: "EDC BNI",
  },
  {
    key: "edcMandiri",
    name: "EDC MANDIRI",
  },
  {
    key: "compliment",
    name: "COMPLIMENT",
  },
  {
    key: "discount",
    name: "DISCOUNT",
  },
  {
    key: "price",
    name: "SUB TOTAL",
  },
  {
    key: "finalPrice",
    name: "TOTAL",
  },
  {
    key: "others",
    name: "LAINNYA",
  },
  {
    key: "lainnya",
    name: "LAINNYA",
  },
];

export const MONEY_LIST = [
  {
    id: 1,
    money: 40000,
    text: "40K",
  },
  {
    id: 2,
    money: 50000,
    text: "50K",
  },
  {
    id: 3,
    money: 70000,
    text: "70K",
  },
  {
    id: 4,
    money: 100000,
    text: "100K",
  },
  {
    id: "000",
    money: "000",
    text: "000",
  },
  {
    id: "UANG_PAS",
    money: 20000,
    text: "UANG PAS",
  },
];

// {
//   key: "debit",
//   name: "Debit",
// },
