import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Flex } from "components/atoms";

function FilterMenuCategory ({ onClick, name, active, icon }) {
  return (
    <Box
      onClick={onClick}
      className={`box-filter${active ? " active" : ""}`}
      shadow
    >
      <Flex
        className="height-full pt-4 pb-2 px-2 overflow-x-hidden"
        direction="column"
        justify="space-between"
        align="center"
      >
        <i className={`feather display-block ${icon}`} style={{ fontSize: "38px" }} />
        <div className="text-center">{name}</div>
      </Flex>
    </Box>
  );
}

FilterMenuCategory.propTypes = {
  onClick: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  active: propTypes.bool.isRequired,
};

export default FilterMenuCategory;
