const initialState = {
  bluetoothCharacteristic: 0,
  count: 0,
};

const bluetoothPrinterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case "SET_BLUETOOTH":
    return {
      ...state,
      bluetoothCharacteristic: payload,
    };

  case "ADD_COUNT":
    return {
      ...state,
      count: payload,
    };

  case "CLEAR_BLUETOOTH":
    return initialState;

  default:
    return state;
  }
};

export default bluetoothPrinterReducer;
