import React from "react";
import propTypes from "prop-types";
import { Button } from "components/atoms";

function Toast ({ show, text, onClose }) {
  if (!show) return null;
  return (
    <div className="toast">
      <div className="mr-4 text-weight-medium">{text}</div>
      <Button
        onClick={onClose}
        className="display-block"
        variant="plain"
      >
        <i className="feather icon-x" />
      </Button>
    </div>
  );
}

Toast.propTypes = {
  show: propTypes.bool.isRequired,
  text: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
};

export default Toast;
