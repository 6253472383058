import React from "react";
import propTypes from "prop-types";
import { Button, Flex } from "components/atoms";

const Modal = ({
  children,
  className,
  onClose,
  show,
  size,
  radius,
  footerButton,
}) => {
  const modalClassName = ["modal-box"];

  if (size) modalClassName.push(`modal-box-${size}`);
  if (radius) modalClassName.push(`modal-box-radius-${radius}`);
  if (className) modalClassName.push(className);

  const handleCloseModal = () => {
    if (onClose) onClose();
  };

  if (!show) return null;
  return (
    <div className="modal">
      <div className="modal-wrapper">
        <div className="modal-background" onClick={handleCloseModal}></div>
        <div className={modalClassName.join(" ")}>
          {onClose && (
            <Button onClick={handleCloseModal} className="modal-close" variant="plain">
              <i className="feather icon-x" />
            </Button>
          )}
          {children}
          {footerButton && (
            <Flex className="mt-7" justify="end">
              {footerButton.cancel && (
                <Button
                  size={size}
                  onClick={footerButton.cancel.onClick}
                  variant="black-1"
                  className="mr-2"
                >
                  {footerButton.cancel.text}
                </Button>
              )}
              {footerButton.confirm && (
                <Button
                  size={size}
                  onClick={footerButton.confirm.onClick}
                >
                  {footerButton.confirm.text}
                </Button>
              )}
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.node,
  onClose: propTypes.func,
  show: propTypes.bool.isRequired,
  size: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  radius: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  footerButton: propTypes.object,
};

Modal.defaultProps = {
  show: false,
  size: "md",
  radius: "xs",
  footerButton: null,
};

export default Modal;
