import React from "react";
import propTypes from "prop-types";
import { Box } from "components/atoms";
import { RPFormat } from "utils/helpers";

const CardPackage = ({ onClick, name, image, price, selected, outOfStock }) => {
  return (
    <Box onClick={onClick} className="card-menu" shadow>
      <div
        className="card-menu-header"
        style={{ backgroundImage: `url("${image || "https://via.placeholder.com/250x250"}")` }}>
      </div>
      <div className="card-menu-body pt-2 pb-3">
        <h4 className="menu-grid-package-title">#SAMPAIKEROKANPISAN</h4>
        <h4 className="menu-grid-title dot">1x Flying Dutchman</h4>
        <h4 className="menu-grid-title dot">2x Blue Ocean</h4>
        <h4 className="menu-grid-title dot">3x V60</h4>
        <h4 className="menu-grid-title dot">10x Rooftop Coffe</h4>
        <h4 className="menu-grid-title dot">1x Rooftop Burger</h4>
        <div className="card-menu-price">{RPFormat(price)}</div>
      </div>
    </Box>
  );
};

CardPackage.propTypes = {
  onClick: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  image: propTypes.string,
  price: propTypes.number.isRequired,
  selected: propTypes.bool,
};

CardPackage.defaultProps = {
  image: "https://via.placeholder.com/250x250",
};

export default CardPackage;
