import React from "react";
import propTypes from "prop-types";

const FormSelect = (props) => {
  const {
    onChange,
    children,
    name,
    label,
    errormessage,
    size,
    multiple,
    value,
  } = props;
  const className = ["position-relative"];
  if (props.className) className.push(props.className);

  return (
    <div className={className.join(" ")}>
      {label && (
        <div className="text-weight-medium mb-2">
          <span className="text-left">{label}</span>
          {errormessage && (
            <span className="color-primary">{` - ${errormessage}`}</span>
          )}
        </div>
      )}
      <select
        onChange={onChange}
        name={name}
        className={`form-input${size ? ` form-input-${size}` : ""}`}
        multiple={multiple}
        value={value}
        {...props}
      >
        <option value="">-- Pilih --</option>
        {children}
      </select>
      {errormessage && (
        <div className="form-input-error-icon">
          <i className="feather icon-alert-triangle" />
        </div>
      )}
    </div>
  );
};

FormSelect.propTypes = {
  onChange: propTypes.func,
  children: propTypes.node.isRequired,
  name: propTypes.string,
  label: propTypes.string,
  errormessage: propTypes.string,
  size: propTypes.oneOf(["sm", "lg"]),
  multiple: propTypes.bool,
  value: propTypes.oneOfType([propTypes.array, propTypes.string, propTypes.number]),
};

export default FormSelect;
