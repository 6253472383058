const initialState = {
  list: [],
};

const packageMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case "SET_PACKAGE_MENU":
    return {
      ...state,
      list: payload,
    };

  case "CLEAR_PACKAGE_MENU":
    return initialState;

  default:
    return state;
  }
};

export default packageMenuReducer;
