import React from "react";
import propTypes from "prop-types";
import { Button, Flex } from "components/atoms";
import { RPFormat } from "utils/helpers";
import { FormInput } from "..";

const OrderMenu = ({
  onClose,
  onClickAddQTY,
  onClickSubQTY,
  onClickNote,
  dataPackageMenu,
  data,
  isPackages,
}) => {
  const { name, amount, sellingPrice: price, note } = data;
  return (
    <>
      <div className="menu-order">
        <div className="menu-order-wrapper">
          <div className="menu-order-detail">
            <div className="menu-order-name">{name}</div>
            <div className="my-2">
              {isPackages && dataPackageMenu.map(({ name, amount }, packageMenuIndex) => (
                <h4 key={packageMenuIndex} className="menu-grid-title dot">{amount}x {name}</h4>
              ))}
            </div>
            {!isPackages && (
              <div className="menu-order-note">
                <Button
                  onClick={onClickNote}
                  className="btn-tambah-catatan mb-1"
                  variant="info"
                  size="sm"
                >
                  <i className="feather icon-clipboard mr-1" />
                  {note ? "Edit" : "Tambah"} Catatan
                </Button>
              </div>
            )}
          </div>
          <div className="menu-order-price">{RPFormat(price)}</div>
          <div className="menu-order-amount">
            <div className="amount">
              <button type="button" className="btn-amount amount-min" onClick={onClickSubQTY}>
                <i className="feather icon-minus" />
              </button>
              <div className="amount-value">{amount}</div>
              <button type="button" className="btn-amount amount-plus" onClick={onClickAddQTY}>
                <i className="feather icon-plus" />
              </button>
            </div>
          </div>
          <div className="menu-order-tools">
            <button
              type="button"
              onClick={onClose}
              className="btn-remove-order"
            >
              <i style={{ fontSize: "22px" }} className="feather icon-x" />
            </button>
          </div>
        </div>
        {
          note &&
          <div className="menu-order-note">{note}</div>
        }
      </div>
    </>
  );
};

OrderMenu.propTypes = {
  onClose: propTypes.func.isRequired,
  onClickAddQTY: propTypes.func.isRequired,
  onClickSubQTY: propTypes.func.isRequired,
  onClickNote: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  isPackages: propTypes.bool.isRequired,
};

OrderMenu.defaultProps = {
  dataPackageMenu: [],
};

export default OrderMenu;
