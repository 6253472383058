import React from "react";
import propTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

function SidebarLink ({ href, icon }) {
  const { pathname } = useLocation();

  return (
    <Link to={href}>
      <div className={`sidebar-link${href === pathname ? " sidebar-link-active" : ""}`}>
        <i className={`feather ${icon}`} style={{ fontSize: "20px" }} />
      </div>
    </Link>
  );
}

SidebarLink.propTypes = {
  href: propTypes.string.isRequired,
  icon: propTypes.node.isRequired,
};

export default SidebarLink;
