import React from "react";
import propTypes from "prop-types";

function FormCheckbox ({ onChange, name, type, label, value }) {
  return (
    <label className="discount-option">
      <input
        onChange={onChange}
        type={type}
        name={name}
        value={value}
        className="discount-option-input"
      />
      <div className="discount-option-mark">{label}</div>
    </label>
  );
}

FormCheckbox.defaultProps = {
  type: "checkbox",
  value: "",
};

FormCheckbox.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string.isRequired,
  type: propTypes.oneOf(["radio", "checkbox"]).isRequired,
  label: propTypes.string.isRequired,
  value: propTypes
    .oneOfType([
      propTypes.string,
      propTypes.number,
      propTypes.bool])
    .isRequired,
  checked: propTypes.bool,
};

export default FormCheckbox;
