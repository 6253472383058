import React from "react";
import propTypes from "prop-types";

const Container = ({ children, className }) => {
  const containerClassName = ["container"];

  if (className) containerClassName.push(className);

  return (
    <div className={containerClassName.join(" ")}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
};

export default Container;
