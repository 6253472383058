import React from "react";
import propTypes from "prop-types";

const Dropdown = ({ children, show }) => {
  if (!show) return null;
  return (
    <div className={`dropdown${show ? " show" : ""}`}>{children}</div>
  );
};

Dropdown.propTypes = {
  children: propTypes.node.isRequired,
  show: propTypes.bool,
};

export default Dropdown;
