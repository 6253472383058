import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({
  onClick,
  children,
  type,
  variant,
  className,
  outline,
  size,
  href,
  loading,
  disabled,
  ...rest
}) => {
  const buttonClass = ["btn", `btn-${variant}`];

  if (outline) buttonClass.push("btn-outline");
  if (size) buttonClass.push(`btn-${size}`);
  if (type === "pagination") buttonClass.push("btn-pagination");
  if (className) buttonClass.push(className);

  if (type === "link") {
    return (
      <Link to={href} className={buttonClass.join(" ")}>{children}</Link>
    );
  }
  return (
    <button
      onClick={onClick}
      className={buttonClass.join(" ")}
      type={type}
      disabled={disabled || loading}
      {...rest}
    >
      { loading && (
        <i className="feather icon-loader mr-2" style={{ fontSize: "18px" }} />
      ) }
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: propTypes.func,
  children: propTypes.node.isRequired,
  type: propTypes.string,
  variant: propTypes.oneOf(["plain", "primary", "success", "warning", "info", "black-1"]),
  className: propTypes.string,
  outline: propTypes.bool,
  size: propTypes.oneOf(["xs", "sm", "lg"]),
  href: propTypes.string,
  loading: propTypes.bool,
  disabled: propTypes.bool,
};

Button.defaultProps = {
  type: "button",
  variant: "primary",
  className: "",
  loading: false,
};

export default Button;
