import React from "react";
import propTypes from "prop-types";
import { Box } from "components/atoms";
import { RPFormat } from "utils/helpers";

const CardMenu = ({ onClick, name, image, price, selected, outOfStock, menus }) => {
  const cardMenuClassName = ["card-menu"];

  if (selected) cardMenuClassName.push("selected");
  if (outOfStock) cardMenuClassName.push("disabled");

  return (
    <Box
      onClick={onClick}
      className={cardMenuClassName.join(" ")}
      shadow
    >
      <div
        className="card-menu-header"
        style={{ backgroundImage: `url("${image || "https://via.placeholder.com/250x250"}")` }}>
      </div>
      <div className="card-menu-body pt-2 pb-3">
        {menus.length > 0
          ? (
            <>
              <h4 className="menu-grid-package-title">{name}</h4>
              {menus.map(({ name, amount }, menuIndex) => (
                <h4 key={menuIndex} className="menu-grid-title dot">{amount}x {name}</h4>
              ))}
            </>
          )
          : (
            <h4 className="menu-grid-title">{name}</h4>
          )}
        <div className="card-menu-price">{RPFormat(price)}</div>
      </div>
    </Box>
  );
};

CardMenu.propTypes = {
  onClick: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  image: propTypes.string,
  price: propTypes.number.isRequired,
  selected: propTypes.bool,
  menus: propTypes.array,
};

CardMenu.defaultProps = {
  image: "https://via.placeholder.com/250x250",
  menus: [],
};

export default CardMenu;
