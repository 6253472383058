import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// #region - IMPORT PAGES
import { AuthenticatedRoute, LoadingPage, UnAuthenticatedRoute } from "components/atoms";
import NotFound from "pages/NotFound";

const Login = lazy(() => import("pages/Login"));
const Home = lazy(() => import("pages/Home"));
const Profile = lazy(() => import("pages/Profile"));
const Setting = lazy(() => import("pages/Setting"));
const TransactionList = lazy(() => import("pages/Transaction/List"));
const TransactionDetail = lazy(() => import("pages/Transaction/Detail"));
const Settlement = lazy(() => import("pages/Settlement"));
// #endregion - IMPORT PAGES

const RouteList = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {/* Not found page */}
          <Route path="*" element={<NotFound />} />

          {/* Unauthenticate routes */}
          <Route element={<UnAuthenticatedRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>

          {/* Authenticated routes */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settlement" element={<Settlement />} />
            <Route path="/transactions" element={<TransactionList />} />
            <Route path="/transactions/:id/detail" element={<TransactionDetail />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default RouteList;
