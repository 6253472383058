import React from "react";

const FilterSearch = ({ onChange }) => (
  <input
    className="form-input-search-table"
    value=""
    placeholder="Search...."
  />
);

export default FilterSearch;
