import { combineReducers } from "redux";
import menuReducer from "./menuReducer.js";
import packageMenuReducer from "./packageMenuReducer.js";
import transactionReducer from "./transactionReducer.js";
import bluetoothPrinterReducer from "./bluetoothPrinterReducer.js";

export default combineReducers({
  menuReducer,
  transactionReducer,
  packageMenuReducer,
  bluetoothPrinterReducer,
});
