import React from "react";
import propTypes from "prop-types";
import { breakpointSizes } from "utils/propTypes";

function Col ({
  children,
  className,
  span,
  sm,
  md,
  lg,
  xl,
}) {
  const colClassName = [`col-${span}`];
  const breakpoints = { sm, md, lg, xl };

  for (const key of Object.keys(breakpoints)) {
    if (breakpoints[key]) colClassName.push(`col-${key}-${breakpoints[key]}`);
  }
  if (className) colClassName.push(className);

  return (
    <div className={colClassName.join(" ")}>{children}</div>
  );
}

Col.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  span: propTypes.oneOf(breakpointSizes()),
  sm: propTypes.oneOf(breakpointSizes()),
  md: propTypes.oneOf(breakpointSizes()),
  lg: propTypes.oneOf(breakpointSizes()),
  xl: propTypes.oneOf(breakpointSizes()),
};

Col.defaultProps = {
  span: 24,
};

export default Col;
